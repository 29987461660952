import { Injectable } from '@angular/core';
import { Mutation } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root',
})
export class CreateSettingsMutation extends Mutation {
  document = gql`
    mutation createToolTimeGeneralSettings(
      $kwhPrice: Float
      $kwhPriceSurcharge: Float
      $machineSuperVisionCompensation: Float
    ) {
      createToolTimeGeneralSettings(
        data: {
          kwhPrice: $kwhPrice
          kwhPriceSurcharge: $kwhPriceSurcharge
          machineSuperVisionCompensation: $machineSuperVisionCompensation
        }
      ) {
        id
        kwhPrice
        kwhPriceSurcharge
        machineSuperVisionCompensation
      }
    }
  `;
}
