import { Injectable } from '@angular/core';
import { Mutation } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root',
})
export class UpdateTriggerableEntityMutation extends Mutation {
  document = gql`
    mutation updateTriggerableEntity(
      $id: String!
      $name: String!
      $description: String
      $type: TriggerableTypes!
      $entityLocationId: String!
      $needsSecondAuthFactor: Boolean
      $needsUserRoles: [UserEntityRole!]
      $hassWebHooksToTrigger: [UpdateHassWebHooksInput!]
      $pricePerMinute: Float
      $pausePriceInPercent: Float
      $maintenanceIntervalInHours: Int
    ) {
      updateTriggerableEntity(
        id: $id
        input: {
          name: $name
          description: $description
          type: $type
          entityLocationId: $entityLocationId
          needsSecondAuthFactor: $needsSecondAuthFactor
          needsUserRoles: $needsUserRoles
          hassWebHooksToTrigger: $hassWebHooksToTrigger
          pricePerMinute: $pricePerMinute
          pausePriceInPercent: $pausePriceInPercent
          maintenanceIntervalInHours: $maintenanceIntervalInHours
        }
      ) {
        id
      }
    }
  `;
}
