import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NGXLogger } from 'ngx-logger';
import { CreateSettingsMutation } from '../mutations/create-settings-mutation';
import { FetchToolTimeSettingsAction } from '../actions/fetch-tool-time-settings.action';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import {
  ToolTimeSettingsState,
  ToolTimeSettingsStateModel,
} from '../tool-time-settings.state';

@Component({
  selector: 'tt-settings',
  templateUrl: './tool-time-settings.component.html',
  styleUrls: ['./tool-time-settings.component.scss'],
})
export class ToolTimeSettingsComponent implements OnInit {
  settingsForm = new FormGroup({
    kwhPrice: new FormControl<number>(0),
    kwhPriceSurcharge: new FormControl<number>(0),
    machineSuperVisionCompensation: new FormControl<number>(0),
  });
  @Select(ToolTimeSettingsState.toolTimeSettings)
  toolTimesSettings: Observable<ToolTimeSettingsStateModel>;

  constructor(
    private store: Store,
    private logger: NGXLogger,
    private createSettingsMutation: CreateSettingsMutation,
  ) {}

  ngOnInit(): void {
    this.store.dispatch(new FetchToolTimeSettingsAction());
    this.toolTimesSettings.subscribe(settings => {
      this.settingsForm.patchValue({
        kwhPrice: settings.kwhPrice,
        kwhPriceSurcharge: settings.kwhPriceSurcharge,
        machineSuperVisionCompensation: settings.machineSuperVisionCompensation,
      });
    });
  }

  saveSettings() {
    this.createSettingsMutation
      .mutate({
        kwhPrice: this.settingsForm.value.kwhPrice,
        kwhPriceSurcharge: this.settingsForm.value.kwhPriceSurcharge,
        machineSuperVisionCompensation: this.settingsForm.value
          .machineSuperVisionCompensation,
      })
      .subscribe(
        ({ data }) => {
          this.logger.log('got create settings data', data);
          this.store.dispatch(new FetchToolTimeSettingsAction());
        },
        error => {
          error.graphQLErrors.map(({ message }, i) =>
            this.logger.error(message, i),
          );
        },
      );
  }
}
