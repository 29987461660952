import { Action, Selector, State, StateContext } from '@ngxs/store';
import { FetchToolTimeSettingsAction } from './actions/fetch-tool-time-settings.action';
import { AllToolTimeSettingsQuery } from './queries/all-tool-time-settings.query';
import { Injectable } from '@angular/core';

export interface ToolTimeSettingsStateModel {
  kwhPrice: number;
  kwhPriceSurcharge: number;
  machineSuperVisionCompensation: number;
}

@State<ToolTimeSettingsStateModel>({
  name: 'toolTimeSettings',
  defaults: {
    kwhPrice: null,
    kwhPriceSurcharge: null,
    machineSuperVisionCompensation: null
  }
})
@Injectable()
export class ToolTimeSettingsState {
  constructor(private allToolTimeSettingsQuery: AllToolTimeSettingsQuery) {}

  @Selector()
  static kwhPrice(state: ToolTimeSettingsStateModel) {
    return state.kwhPrice;
  }

  @Selector()
  static toolTimeSettings(
    state: ToolTimeSettingsStateModel
  ): ToolTimeSettingsStateModel {
    return state;
  }

  @Action(FetchToolTimeSettingsAction)
  async getToolTimeSettings(
    { patchState }: StateContext<ToolTimeSettingsStateModel>,
    action: FetchToolTimeSettingsAction
  ) {
    return new Promise(async (resolve, reject) => {
      this.allToolTimeSettingsQuery
        .watch(
          {},
          {
            fetchPolicy: 'network-only'
          }
        )
        .valueChanges.subscribe(
        ({ data, loading }: { data: any; loading: boolean }) =>
          resolve(
            patchState({
              kwhPrice: data?.toolTimeGeneralSettingses[0]?.kwhPrice,
              kwhPriceSurcharge:
              data?.toolTimeGeneralSettingses[0]?.kwhPriceSurcharge,
              machineSuperVisionCompensation:
              data?.toolTimeGeneralSettingses[0]?.machineSuperVisionCompensation
            })
          )
      );
    });
  }
}
